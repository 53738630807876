import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import { useStyles } from "./style"
import { useArticlePageContext } from "@talentinc/gatsby-theme-ecom/contexts/ArticlePageContext"
import Section from "@talentinc/gatsby-theme-ecom/components/Section/Section"
import { navigate } from "gatsby"
import { useBETelemetry } from "@talentinc/gatsby-theme-ecom/hooks/useTelemetry"
import { useTranslation } from "react-i18next"
import { getLinkUrl } from "@talentinc/gatsby-theme-ecom/utils/blog/url"

export default function RelatedArticleSection() {
  const { classes } = useStyles()
  const { relatedArticles } = useArticlePageContext()
  const telemetry = useBETelemetry()
  const { t } = useTranslation()

  // next blog event handler
  const handleNextBlog = (slug: string) => {
    const { href } = window.location
    telemetry.track({
      event: "click_next_blog_bottom",
      properties: {
        label: "related_article",
        url: href,
        target_blog: slug,
      },
    })

    const articleUrl = getLinkUrl(t("blog.slugs.articlePage", { articleSlug: slug }))
    navigate(articleUrl)
  }

  return (
    <Box className={classes.articleSection}>
      <Section>
        {/* related article section  */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className={classes.articleHeading}>
              {t("blog.article.relatedArticle.relatedArticleTitle")}
            </Typography>
          </Grid>

          {relatedArticles?.map((article) => {
            return (
              <Grid key={article.id} item xs={12} sm={6} md={4} onClick={() => article.slug ? handleNextBlog(article?.slug): null}>
                <img className={classes.articleImg} src={article?.heroImage?.file?.url || ""} loading="lazy" />
                <Typography className={classes.articleTitle}>{article.title}</Typography>
              </Grid>
            )
          })}
        </Grid>
      </Section>
    </Box>
  )
}
