import React from "react"
import TopLayout from "@talentinc/gatsby-theme-ecom/layouts/TopLayout"
import { PageContextType, PageDataType } from "@talentinc/gatsby-theme-ecom/types/page"
import HeroSection from "@talentinc/gatsby-theme-ecom/components/Blog/Category/HeroSection"
import BodySection from "@talentinc/gatsby-theme-ecom/components/Blog/Category/BodySection"
import Footer from "@talentinc/gatsby-theme-ecom/components/Footer/Footer"
import { useCategoryPageContext } from "@talentinc/gatsby-theme-ecom/contexts/CategoryPageContext"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import { getLinkUrl } from "@talentinc/gatsby-theme-ecom/utils/blog/url"

export default function CategoryPage({ pageContext, pageData }: { pageContext: PageContextType; pageData: PageDataType }) {
  const { currentPage, numPages, slug, articleByCategory, categoryBySlug, allCategory } = useCategoryPageContext()
  const { t } = useTranslation()
  const handlePageChange = (value: number) => {
    const url = getLinkUrl(t("blog.slugs.categoryPage", { categorySlug: value === 1 ? slug : `${slug}/${value}` }))
    navigate(url)
  }

  const handleSearchSubmit = (value: string) => {
    if(!value) return
    const urlParams = new URLSearchParams({ kw: value })
    const url = getLinkUrl(t("blog.slugs.categorySearchPage", { queryParams: urlParams.toString() }))
    navigate(url)
  }

  return (
    <TopLayout pageContext={pageContext} pageData={pageData}>
      <HeroSection categoryBySlug={categoryBySlug} allCategory={allCategory || []} onSearchSubmit={handleSearchSubmit} />
      <BodySection
        articlesData={articleByCategory || []}
        pagination={{ currentPage, numPages, onChange: handlePageChange }}
        onSearchSubmit={null}
        noResultText={t("blog.category.body.noResultFound")}
      />
      <Footer />
    </TopLayout>
  )
}
