import React, { createContext, ReactNode, useContext } from "react"
import { BlogCategoryData } from "@talentinc/gatsby-theme-ecom/types/blog/category"

const initialContextValue = {
  allCategory: null,
  articleByCategory: null,
  categoryBySlug: null,
  currentPage: 1,
  numPages: 1,
  slug: "",
}

const CategoryPageContext = createContext<BlogCategoryData>(initialContextValue)

export const CategoryPageContextProvider = ({ children, value }: { children: ReactNode; value: BlogCategoryData }) => {
  return <CategoryPageContext.Provider value={value}>{children}</CategoryPageContext.Provider>
}

export const useCategoryPageContext = () => useContext(CategoryPageContext)
