import React from "react"
import Section from "@talentinc/gatsby-theme-ecom/components/Section/Section"
import { Box, Card, CardContent, CardMedia, Grid, Stack, Typography } from "@mui/material"
import { useStyles } from "../style"
import { CardActions } from "@mui/material"
import { useBlogRootContext } from "@talentinc/gatsby-theme-ecom/contexts/BlogRootContext"
import { Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { getLinkUrl } from "@talentinc/gatsby-theme-ecom/utils/blog/url"
import { useTranslation } from "react-i18next"

const HeroBottom = ({ withAmandaCard }: { withAmandaCard: boolean }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { featuredBlogs, editorPicksUrl, featuredEditor, featuredDescription, brandName } = useBlogRootContext()

  const domainFilteredBlogs = featuredBlogs
    ? featuredBlogs.filter((blog) => blog.domain && brandName && blog.domain.includes(brandName))
    : []
  const slicedFeatureBlogs = domainFilteredBlogs.slice(0, withAmandaCard ? 6 : 8)

  return (
    <Box>
      <Section>
        <Grid container spacing={{ md: 8, xs: 4 }}>
          {withAmandaCard && (
            <Grid md={3.8} sm={4} xs={12} item className={classes.heroMainItemCard}>
              <Card className={classes.heroImgContainer}>
                <CardMedia component="img" image={featuredEditor?.file?.url || ""} alt={featuredEditor?.title || ""} loading="lazy" />
                <CardContent>
                  {featuredDescription &&
                    documentToReactComponents(JSON.parse(featuredDescription.raw), {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (_, children) => {
                          return <Typography className={classes.leftCardDescription}>{children}</Typography>
                        },
                        [INLINES.HYPERLINK]: (node, children) => {
                          const { uri } = node.data
                          return (
                            <Link to={uri} className={classes.leftCardLink}>
                              {children}
                            </Link>
                          )
                        },
                      },
                    })}
                </CardContent>
                <CardActions>
                  <Link
                    className={classes.blogLink}
                    to={getLinkUrl(t("blog.slugs.categoryPage", { categorySlug: editorPicksUrl?.slug }))}
                  >
                    <Typography className={classes.ViewAllLink}>{t("blog.blogHome.hero.amandaCardLink")}</Typography>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          )}
          <Grid
            md={withAmandaCard ? 8.2 : 12}
            sm={withAmandaCard ? 8 : 12}
            xs={12}
            item
            className={classes.heroMainItemCard}
          >
            <Grid spacing={{ md: 8, xs: 4 }} className={classes.heroRightContainer} container>
              {!withAmandaCard && (
                <Grid item xs={12}>
                  <Typography className={classes.bodyCardTitle}>{t("blog.blogHome.hero.recentArtcleTitle")}</Typography>
                </Grid>
              )}
              {slicedFeatureBlogs?.map((featuredBlog) => (
                <Grid
                  key={featuredBlog.id}
                  item
                  md={withAmandaCard ? 4 : 3}
                  sm={withAmandaCard ? 6 : 4}
                  xs={12}
                  className={classes.heroItemCard}
                >
                  <Stack spacing={{ md: 2, xs: 1 }} className={classes.rightCardContainerHero}>
                    <Link
                      className={classes.blogLink}
                      to={getLinkUrl(t("blog.slugs.articlePage", { articleSlug: featuredBlog.slug }))}
                    >
                      <Box
                        component="img"
                        src={featuredBlog?.heroImage?.file?.url || ""}
                        alt={featuredBlog?.heroImage?.title || ""}
                        className={classes.righCardImg}
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className={classes.blogLink}
                      to={getLinkUrl(t("blog.slugs.articlePage", { articleSlug: featuredBlog.slug }))}
                    >
                      <Typography className={classes.righCardLink}>{featuredBlog.title}</Typography>
                    </Link>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Section>
    </Box>
  )
}

export default HeroBottom
