import React, { ChangeEventHandler, useEffect, useRef, useState } from "react"
import { Avatar, Box, Grid, Stack, Typography, Button, InputBase, CircularProgress } from "@mui/material"
import HandHoldingStar from "../../../images/hand-holding-star.svg"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { useArticlePageContext } from "@talentinc/gatsby-theme-ecom/contexts/ArticlePageContext"
import { Divider } from "@mui/material"
import Section from "@talentinc/gatsby-theme-ecom/components/Section/Section"
import { Link, navigate } from "gatsby"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useStyles } from "./style"
import { callWithRetry } from "@talentinc/gatsby-theme-ecom/utils/utils"
import useUser from "@talentinc/gatsby-theme-ecom/hooks/useUser"
import { useTranslation } from "react-i18next"
import { useBETelemetry } from "@talentinc/gatsby-theme-ecom/hooks/useTelemetry"
import useAxios from '../../../hooks/useAxios'

type StatusCodes = "idle" | "submitting" | "success" | "error" | "409"

export default function BodySection() {
  const { t } = useTranslation()
  const { refetchSession } = useUser()
  const axios = useAxios({ withPartnerAuth: true })

  const { classes } = useStyles()
  const matchesSm = useMediaQuery("(max-width:600px)")
  const isBelowMd = useMediaQuery("(max-width:768px)")
  const {
    body,
    floatingSideBanners,
    author,
  } = useArticlePageContext()

  const leftSectionRef = useRef<HTMLDivElement>(null)
  const gridContainerRef = useRef<HTMLDivElement>(null)
  const [showTopFloatingBanner, setShowTopFloatingBanner] = useState(true)
  const [email, setEmail] = useState<{ value: string; error: string | null }>({
    value: "",
    error: null,
  })
  const [status, setStatus] = useState<StatusCodes>("idle")
  const [errorMessage, setErrorMessage] = useState<string>("")
  const telemetry = useBETelemetry()

  // email handle change
  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setEmail((prev) => ({ ...prev, value: event.target.value }))
  }

  // toggle floating banner based on scroll posisition
  useEffect(() => {
    if (isBelowMd) {
      setShowTopFloatingBanner(true)
      return
    }

    const handleScroll = () => {
      if (leftSectionRef.current && gridContainerRef.current) {
        const gridTop = gridContainerRef.current.offsetTop
        const leftSectionTop = leftSectionRef.current.offsetTop
        const leftSectionHeight = leftSectionRef.current.offsetHeight
        const scrollY = window.scrollY
        const relativeScrollTop = scrollY - leftSectionTop + gridTop
        const scrollProgress = (relativeScrollTop / leftSectionHeight) * 100

        if (scrollProgress >= 50) {
          setShowTopFloatingBanner(false)
        } else {
          setShowTopFloatingBanner(true)
        }
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [isBelowMd])
  //  validate emaill address to subscribe newsletter
  const validateEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    let error = null
    if (value === "") {
      error = t("blog.article.body.careerAdviceFloatingBanner.emailValidationError")
    } else if (!emailRegex.test(value)) {
      error = t("blog.article.body.careerAdviceFloatingBanner.emailValidationError")
    }

    return { isValid: !error, error }
  }
  //  subscribe newsletter handler
  const handleSubscribe = () => {
    let { error } = validateEmail(email.value)
    setEmail((prev) => ({ ...prev, error }))
    if (!error) {
      setStatus("submitting")
      axios
        .post(
          "/api/v2/leads/email",
          { email: email.value },
          {
            headers: {
              "Content-Type": "application/json",
            },
            validateStatus: (status) => [200, 201, 409].includes(status),
          }
        )
        .then(async ({ status }) => {
          setStatus(status === 409 ? `${status}` : "success")
          await callWithRetry({
            fn: refetchSession,
            retries: 3,
            predicate: async (response) => {
              const { data } = await response
              return !!data?.lead_auth_token
            },
          })
        })
        .catch((e) => {
          setErrorMessage(e.message)
          setStatus("error")
        })
    }
  }
  // navigate user on resume-view page and Article page floating banner events
  const handleGetFreeExpertReview = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    telemetry.track({
      event: "click_get_review_banner",
      properties: {
        label: "get_free_expert_review",
        cta: "get_a_free_expert_review",
      },
    })
    navigate(t("blog.slugs.resumeReviewPage"))
  }
  return (
    <Box ref={gridContainerRef}>
      <Section>
        <Grid container spacing={4} className={classes.blogSection} ref={leftSectionRef}>
          <Grid item lg={7} sm={12} md={8} className={classes.blogGrid}>
            {/* body sections  */}
            <Stack spacing={4} className={classes.authorContainer}>
              {body && documentToReactComponents(JSON.parse(body.raw), {
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (_, children) => {
                    return <Typography className={classes.blogRichText}>{children}</Typography>
                  },
                  [INLINES.HYPERLINK]: (node, children) => {
                    const { uri } = node.data
                    return (
                      <Link className={classes.articleLinks} to={uri}>
                        {children}
                      </Link>
                    )
                  },
                  [BLOCKS.HEADING_1]: (_, children) => {
                    return (
                      <Typography variant="h1" className={classes.blogHeadings}>
                        {children}
                      </Typography>
                    )
                  },
                  [BLOCKS.HEADING_2]: (_, children) => {
                    return (
                      <Typography variant="h2" className={classes.blogHeadings}>
                        {children}
                      </Typography>
                    )
                  },
                  [BLOCKS.HEADING_3]: (_, children) => {
                    return (
                      <Typography variant="h3" className={classes.blogHeadings}>
                        {children}
                      </Typography>
                    )
                  },
                  [BLOCKS.HEADING_4]: (_, children) => {
                    return (
                      <Typography variant="h4" className={classes.blogHeadings}>
                        {children}
                      </Typography>
                    )
                  },
                  [BLOCKS.HEADING_5]: (_, children) => {
                    return (
                      <Typography variant="h5" className={classes.blogHeadings}>
                        {children}
                      </Typography>
                    )
                  },
                  [BLOCKS.HEADING_6]: (_, children) => {
                    return (
                      <Typography variant="h6" className={classes.blogHeadings}>
                        {children}
                      </Typography>
                    )
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node) => {
                    const { id } = node.data.target.sys
                    const { file, title } = body.references.find((ref) => ref.contentful_id === id) as any

                    if (file.contentType.startsWith("video"))
                      return (
                        <video width="100%" height="100%" controls>
                          <source src={file.url} type="video/mp4" />
                        </video>
                      )
                    if (file.contentType.startsWith("image"))
                      return <img className={classes.bodyImg} src={file.url} alt={title} loading="lazy" />

                    return <></>
                  },
                },
              })}
            </Stack>
            <Divider className={classes.authorDivider} />
            {/* author blurb section  */}
            {author && <Grid container spacing={6} className={classes.authorContainer}>
              <Grid item xs={12} sm={12} className={classes.authorAvatarContainer}>
                <Grid container spacing={matchesSm ? 11 : 14}>
                  <Grid item xs={1} sm={2} lg={1}>
                    {author.authorImage?.file?.url && <Avatar alt={author.authorImage?.file?.url} src={author.authorImage?.file?.url} className={classes.authorAvatar} />}
                  </Grid>
                  <Grid item xs={8} sm={10} className={classes.authorNameGrid}>
                    <Typography className={classes.authorLabel}>{t("blog.article.body.authorBlurb.title")}</Typography>
                    <Typography className={classes.authorName}>{`${author.authorName}, ${author.authorTagline}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={14}>
                  <Grid item xs={0} sm={2} lg={1}></Grid>
                  <Grid item xs={12} sm={10}>
                    {author.authorDescription && documentToReactComponents(JSON.parse(author.authorDescription.raw), {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => {
                          return <Typography className={classes.authorDescription}>{children}</Typography>
                        },
                        [INLINES.HYPERLINK]: (node, children) => {
                          const { uri } = node.data
                          return (
                            <Link className={classes.articleLinks} to={uri}>
                              {children}
                            </Link>
                          )
                        },
                      },
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}
          </Grid>
          {/* floating banners  */}
          <Grid item lg={5} xs={12} md={4}>
            <Stack spacing={50} className={classes.resumeStackMain}>
              {showTopFloatingBanner ? (
                <Grid container spacing={{ xs: 0, md: 1 }} className={classes.floatingBannerTop}>
                  <Grid lg={12} md={12} xs={12} className={classes.floatingBannerGrid} item>
                    <Box className={classes.resumeStackBox}>
                      {floatingSideBanners?.sideBannerImage?.url && <img
                        src={floatingSideBanners.sideBannerImage.url}
                        alt={floatingSideBanners.sideBannerImage.url}
                        className={classes.resumeStackImg}
                        loading="lazy"
                      />}
                      <Box className={classes.resumeTitleBox}>
                        <Typography className={classes.resumeTitle}>
                          {t("blog.article.body.floatingBanner.title")}
                        </Typography>
                        <Button className={classes.resumeBtn} onClick={(e) => handleGetFreeExpertReview(e)}>
                          {t("blog.article.body.floatingBanner.buttonTitle")}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1} className={classes.careerAdviceContainer}>
                  <Grid item lg={12} md={12} xs={12}>
                    <Stack
                      spacing={{ xs: 2, lg: 4 }}
                      className={classes.careerAdviceBox}
                      direction={{ xs: "column", sm: "column" }}
                    >
                      <img src={HandHoldingStar} alt="/images/Discussion-1.png" className={classes.careerAdviceImg} loading="lazy" />
                      <Box className={classes.careerAdviceTitleBox}>
                        <Typography className={classes.careerAdviceTitle} align="center">
                          {t("blog.article.body.careerAdviceFloatingBanner.title")}
                        </Typography>
                        <Typography className={classes.careerAdviceDescription} align="center">
                          {" "}
                          {t("blog.article.body.careerAdviceFloatingBanner.description")}
                        </Typography>
                      </Box>
                      <Stack spacing={1}>
                        {status === "success" || status === "409" ? (
                          <Box className={classes.successMessage}>{t("newsletterForm.successMessage")}</Box>
                        ) : (
                          <>
                            <InputBase
                              id="email"
                              placeholder={t("blog.article.body.careerAdviceFloatingBanner.emailPlaceholder")}
                              className={classes.careerAdviceEmailInput}
                              onChange={handleChange}
                              value={email.value}
                            />
                            <Button
                              className={classes.careerAdviceBtn}
                              onClick={handleSubscribe}
                              disabled={status === "submitting"}
                            >
                              {status === "submitting" ? (
                                <CircularProgress color="inherit" size="1em" />
                              ) : (
                                t("blog.article.body.careerAdviceFloatingBanner.buttonTitle")
                              )}
                            </Button>
                          </>
                        )}

                        {email.error && <Box className={classes.errorMessage}>{email.error}</Box>}

                        {status === "error" && <Box className={classes.errorMessage}>{errorMessage}</Box>}
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Section>
    </Box>
  )
}
