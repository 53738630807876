import { graphql, PageProps } from "gatsby"
import React from "react"
import CategoryPage from "@talentinc/gatsby-theme-ecom/pages/blog/CategoryPage"
import pageData from "../../data/career-advice/category-page"
import { CategoryPageContextProvider } from "@talentinc/gatsby-theme-ecom/contexts/CategoryPageContext"
import { CategoryPageData } from "@talentinc/gatsby-theme-ecom/types/blog/category"
import { PageContextType } from "@talentinc/gatsby-theme-ecom/types/page"

export default function Page({
  pageContext,
  data,
}: PageProps<CategoryPageData, PageContextType & { numPages: number; currentPage: number; slug: string }>) {
  const { numPages, currentPage, slug } = pageContext

  const { categoryBySlug } = data

  const updatedPageData = {
    ...pageData,
    title: categoryBySlug?.name,
    slug: categoryBySlug?.slug,
    seo: {
      ...pageData.seo,
      pageTitle: categoryBySlug?.metaTitle || null,
      description: categoryBySlug?.metaDescription || null,
      noIndex: !categoryBySlug?.indexStatus,
      canonicalUrl: categoryBySlug?.canonicalUrl || null,
    },
  }

  return (
    <CategoryPageContextProvider
      value={{
        numPages,
        currentPage,
        slug,
        articleByCategory: data.articleByCategory.nodes,
        categoryBySlug: data.categoryBySlug,
        allCategory: data.allCategory.nodes,
      }}
    >
      <CategoryPage pageContext={pageContext} pageData={updatedPageData} />
    </CategoryPageContextProvider>
  )
}

export const query = graphql`
  query ($language: String, $slug: String, $limit: Int, $skip: Int, $node_locale: String, $brandName: String) {
    allCategory: allContentfulBlogCategory(filter: { node_locale: { eq: $node_locale }, domain: { eq: $brandName } }) {
      nodes {
        id
        name
        slug
        backgroundColor
      }
    }
    categoryBySlug: contentfulBlogCategory(slug: { eq: $slug }) {
      id
      name
      slug
      backgroundColor
      metaTitle
      metaDescription
      indexStatus
      canonicalUrl
    }
    articleByCategory: allContentfulBlogArticle(
      limit: $limit
      skip: $skip
      filter: { blogCategory: { slug: { eq: $slug } }, domain: { eq: $brandName }, node_locale: { eq: $node_locale } }
    ) {
      nodes {
        id
        title
        slug
        heroImage {
          file {
            url
          }
          title
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
